import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import FileUploadButton from './FileUploadButton';
import DashboardNav from '../components/DashboardNav';
import BarChart from '../components/DashboardRecommendationProgress';
import ContractTrackerProgressBar from '../components/ContractTrackerProgressBar';
import { AgGridReact } from 'ag-grid-react'
import { formatTeam, formatHealthCheckAsJob, formatSpyCareTeam } from '../utils'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { calculateSavings } from '../utils/calculate';
import 'react-circular-progressbar/dist/styles.css';
import styles from './SpycareDashboard.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { useJob } from '../components/JobProvider'
import { useAuth } from '../components/AuthProvider'

const SpycareDashboard = () => {
  const history = useHistory()
  const [ data, setData ] = useState(null)
  const [ clientName, setClientName ] = useState(null)
  const [ resources, setResources ] = useState([])
  const [ teamMembers, setTeamMembers ] = useState([])
  const [ contracts, setContracts ] = useState([])
  const [ showModal, setShowModal ] = useState(false);
  const { spycareJobId: spycareJob } = useJob()
  const [ spycareServiceId, setSpyCareServiceId ] = useState(null);
  const { token } = useAuth()

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    }
  }, [])

  const calculateDatePercentage = (endString, term) => {
    const end = moment(endString);
    const start = moment(end).subtract(term, 'months')
    let p = Math.round((moment().diff(start, 'days')) / (end.diff(start, 'days')) * 100)
    p = p > 100 ? p = 100 : p;
    const daysLeft = end.diff(moment(), 'days')
    return {
      percent: p,
      daysLeft: daysLeft
    };
  }

  const fileUploadButtonCallback = (files) => {
    if (spycareJob) {
      const formData = new FormData();
      formData.append("spycareJobId", spycareJob);
      for (let i = 0; i < files.length; i++) {
        formData.append("files[" + i + "]", files[ i ]);
        formData.append("documentNames[" + i + "]", files[ i ].name);
      }

      fetch(`${process.env.REACT_APP_API_URL}/data/spycare/multipart_document`, {
        body: formData,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => res.json())
        .then(docs => {
        },
          (error) => {
            console.log(error);
          });
    }
  }

  useEffect(() => {
    if (spycareJob) {
      fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(job => {

          setClientName(job.client?.clientName)
          setSpyCareServiceId(job.spycareService?.spycareServiceId)
        })

      fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/${spycareJob}/team`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(members => {
          const memberArr = formatSpyCareTeam(members)
          setTeamMembers(memberArr)
        })

      fetch(`${process.env.REACT_APP_API_URL}/data/spycare/document/job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(docs => {
          setResources(docs.filter(doc => doc.documentTypeId === 8))
        })
      fetch(`${process.env.REACT_APP_API_URL}/data/contract/spycare_job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => res.json())
        .then(resContracts => {
          const displayContracts = resContracts.filter((contract) => contract.topContract === 1).map((contract) => {
            const parsedTerm = contract.term.replace(/[^0-9]/g, '');
            return {
              provider: contract.provider,
              accountNumber: contract.accountNumber,
              termCompleted: parsedTerm ? calculateDatePercentage(contract.expirationDate, parsedTerm) : null,
              expirationDate: contract.expirationDate != null ? new Date(contract.expirationDate.replace(/-/g, '\/')).toLocaleDateString() : 'N/A',
              serviceCategory: contract.servicesIncluded,
            }
          })
          setContracts(displayContracts)
        })
    }
  }, [ spycareJob ])

  return (
    <>
      {/* <DashboardNav /> */}
      {/* <Container className="mt-3">
        <Row>
          <Col xs={12} className="d-flex justify-content-between align-content-center">
            <h1 className="mb-0">{clientName} SpyCare Dashboard</h1>
          </Col>
        </Row>
      </Container> */}
      <DashboardNav/>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Portal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Button onClick={() => {
              const token = window.localStorage.getItem('token');
              window.open(`${process.env.REACT_APP_REDIRECT_URL}/saml.php?token=${token}`, '_blank').focus();
            }}>Fixed</Button>
            <Button onClick={() => {
              window.open('https://clients.spyglass.net/oauth/authorize?response_type=code&client_id=visage-v1&redirect_uri=https://prodn02.mymobilitycentral.com/oauth/v1/auth.agi?ssoUrlMarker=spyglasssso&scope=read', '_blank').focus();
            }}>Wireless</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Container className="mt-3">
        <Row className={styles.cardContainerVh100}>
          <h1 className="mb-3">{clientName} SpyCare Dashboard</h1>
          <Col xs={12} lg={6}>
            <Card className={`card-h-50`} style={{ height: '250px' }}>
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Contact Us</Card.Title>
                </div>
                <div className="d-sm-flex justify-content-start align-items-center">
                  <div className={styles.projectLead}>
                    <div className={styles.pfp}>
                      <div className={styles.teamMemberPhoto} style={teamMembers[ 0 ]?.url ? { backgroundImage: `url(${teamMembers[ 0 ].url})` } : {}}></div>
                    </div>
                    <div className={styles.leadInfo}>
                      <h3 className="mb-1">{teamMembers[ 0 ]?.firstName} {teamMembers[ 0 ]?.lastName}</h3>
                      <h4 className="mb-1">{teamMembers[ 0 ]?.title}</h4>
                      {/*<div>Team</div>*/}
                      <div>{teamMembers[ 0 ]?.phone}</div>
                      <div className="color-primary"><a href={`mailto:${teamMembers[ 0 ]?.email}`}>{teamMembers[ 0 ]?.email}</a></div>
                    </div>
                  </div>
                  <div className="">
                    <Button variant="secondary" size="lg" className="mt-1 ms-1 me-1" onClick={() => { if (teamMembers[ 0 ]?.meetingLink) { window.location.href = teamMembers[ 0 ].meetingLink } }}>
                      Schedule a Meeting
                    </Button>
                  </div>
                </div>

              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Card className={`card-h-50`} style={{ height: '250px' }}>
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Resources</Card.Title>
                </div>
                <div className={styles.resourcesContainer}>
                  {
                    resources.map((r) => (
                      <div className={styles.resourceRow} key={r.name}>
                        <div className={styles.resource}>
                          <div>
                            {r.filetype && r.filetype.includes('pdf')
                              ? (<a href={r.url} rel="noreferrer noopener" target="_blank">{r.documentName}</a>)
                              : !r.base64
                                ? r.fileName.charAt(0) !== '/'
                                  ? (<a href={r.fileName} rel="noreferrer noopener" target="_blank">{r.documentName}</a>)
                                  : <Link to={r.fileName}>{r.documentName}</Link>
                                : (<Link to={r.fileName}>{r.documentName}</Link>)
                            }
                          </div>
                          <div className={styles[ r.filetype && r.filetype.includes('pdf') ? 'PDF' : 'LINK' ]}>
                            {r.filetype && r.filetype.includes('pdf') ? 'PDF' : 'LINK'}
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  }
                </div>
              </Card.Body>
              {/* <Card.Footer><Link to="/file-manager" className="card-link">View File Manager</Link></Card.Footer> */}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4} >
            <div className={`${styles.serviceTile} d-flex justify-content-center align-items-center`} style={{ cursor: 'pointer' }}>
              <Form>
                <img onClick={() => {
                  if (spycareServiceId === 1) {
                    setShowModal(true)
                  } else if (spycareServiceId === 2) {
                    // LANDLINE SSO

                    const token = window.localStorage.getItem('token');
                    window.open(`${process.env.REACT_APP_REDIRECT_URL}/saml.php?token=${token}`, '_blank').focus();
                  } else if (spycareServiceId === 3) {
                    // WIRELESS SSO

                    window.open('https://clients.spyglass.net/oauth/authorize?response_type=code&client_id=visage-v1&redirect_uri=https://prodn02.mymobilitycentral.com/oauth/v1/auth.agi?ssoUrlMarker=spyglasssso&scope=read', '_blank').focus();
                  } else if (spycareServiceId === 4) {
                    // BOTH AMI Wireless SSO
                    const token = window.localStorage.getItem('token');
                    window.open(`${process.env.REACT_APP_REDIRECT_URL}/saml.php?token=${token}`, '_blank').focus();
                  }
                }} src="https://www.spyglass.net/wp-content/uploads/2024/05/SpyCare-2.0-New-Icons-2024-Expense-Management.png" alt="" height={400} />
              </Form>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className={`${styles.serviceTile} d-flex justify-content-center align-items-center`}>
              <a href="/sourcing-management">
                <img src="https://www.spyglass.net/wp-content/uploads/2024/05/SpyCare-2.0-New-Icons-2024-SourcingManagemetn.png" alt="" height={400} />
              </a>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className={`${styles.serviceTile} d-flex justify-content-center align-items-center`}>
              <a href={`mailto:${teamMembers[ 0 ]?.email}?cc=spycare@spyglass.net`}>
                <img src="https://www.spyglass.net/wp-content/uploads/2024/05/SpyCare-2.0-New-Icons-2024-Dedicated-Support.png" alt="" height={400} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SpycareDashboard
