import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Row, Col, Button, Card, Tab, ListGroup, Form, Modal } from 'react-bootstrap/';
import DashboardNav from "../components/DashboardNav";
import FileUploadButton from '../components/FileUploadButton';
import styles from './Inventories.module.scss'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import invColumns from '../json/inventory_columns.json';
import GridModal from '../components/GridModal';
import EditGridModal from '../components/EditGridModal';
import { deleteGrid } from '../utils';
import { formatters, getters, renderers } from '../recRendering';
import { useJob } from '../components/JobProvider';
import { useLocation, useParams } from 'react-router-dom';
import { usePortal } from '../components/PortalProvider';
import { useAuth } from '../components/AuthProvider';
import AddRecommendationModal from '../components/AddRecommendationModal';
import EditRecommendationModal from '../components/EditRecommendationModal';
import { useAccess } from '../components/AccessProvider';

const Inventories = () => {
  const { jobId: job, spycareJobId: spycareJob } = useJob()
  const search = useLocation().search
  const gridId = new URLSearchParams(search).get('gridId')
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)
  const [selectedType, setSelectedType] = useState('Local')
  const [gridData, setGridData] = useState([])
  const [gridColumns, setGridColumns] = useState(null)
  const [initialGridFilters, setInitialGridFilters] = useState(null)
  const [gridConfigs, setGridConfigs] = useState([])
  const [gridConfig, setGridConfig] = useState(null)
  const [grid, setGrid] = useState(null)
  const [editGrid, setEditGrid] = useState(null)
  let gridTypes = useRef([])
  //const portal = usePortal()
  const { healthCheckId } = useParams()
  const { token } = useAuth()
  const [isRecModalActive, setRecModalActive] = useState(false)
  const [isEditRecModalActive, setEditRecModalActive] = useState(false)
  const [authorities, setAuthorities] = useState(null)
  const [currentInventory, setCurrentInventory] = useState(null)
  const [notesInv, setNotesInv] = useState(null)
  const { invAccess } = useAccess()

  // Health Checks disabled, page should only show snapshot inventory
  const portal = 'SpyGlass';

  const generateRowData = () => (
    gridData.filter(i => {
      if (selectedType.includes('All')) {
        return i.inventoryType.name === 'Local'
          || i.inventoryType.name === 'Data'
          || i.inventoryType.name === 'LD'
      }
      return i.inventoryType.name === selectedType
    }))

  const resetColumns = (cols, type = selectedType) => {
    return cols.filter(c => !c.for || c.for.includes(type.toLowerCase()))
      .sort((a, b) => {
        if (type === "Local") {
          return a.localIndex - b.localIndex
        } else if (type === "Data") {
          return a.dataIndex - b.dataIndex
        } else if (type === "LD") {
          return a.ldIndex - b.ldIndex
        } else if (type === "All") {
          return a.allIndex - b.allIndex
        }
        return a.wirelessIndex - b.wirelessIndex
      })
      .map(c => ({
        ...c,
        hide: false,
        pinned: false,
        pivot: false,
        rowGroup: false,
        sort: false,
      }))
  }

  const classRules = {
    'baseRate': {
      //green
      'bg-rate-reduction': (data) => {
        return data.data?.recCategory === 'Cost Reduction' || (data.data?.recCategory === 'Eliminate' && data.data.proposedBaseRate)
      },
      //blue
      'bg-retro-rec': (data) => {
        if (!data.data?.recommendations.length) { return 0 }
        let rec = data.data.recommendations[0]
        return rec.retroactive === 'Not Billing Per Contract'
      }
    }
  }

  const rowClassRules = useMemo(() => {
    return {
      //red
      [styles.hasEliminationRec]: (params) => {
        return params.data?.recCategory === 'Eliminate'
      },
      //blue
      [styles.hasRetroRec]: (params) => {
        if (!params.data?.recommendations.length) { return 0 }
        let rec = params.data.recommendations[0]
        let blueValues = ['Previously Canceled', 'Tax', 'Disconnected', 'Other']
        return blueValues.includes(rec.retroactive)
      }
    }
  }, []);

  const generateColumns = () => {
    const fullColumns = (authorities?.includes("ROLE_Employee") ? gridColumns : gridColumns.filter(c => c.field !== 'addRecommendation')).map((c) => {
      const colDef = invColumns.find((invCol) => invCol.field === c.colId)
      if (colDef) {
        if (colDef.field === 'ldUsage') {
          colDef.aggFunc = params => {
            const minutesPattern = /^\d+\.?\d* Minutes$/
            let dollarsTotal = 0
            let minutesTotal = 0
            params.values.forEach(value => {
              if (minutesPattern.test(value)) {
                const minutes = String(value).split(' Minutes')[0]
                minutesTotal += +minutes
              } else if (String(value).charAt(0) === '$') {
                const dollars = String(value).split(' ')[0].substring(1);
                dollarsTotal += +dollars
              }
            });
            return `$$${dollarsTotal.toFixed(2)}, ${minutesTotal.toFixed(2)} min`
          }
        }
        return { ...c, ...colDef }
      }
      return c
    })
    return fullColumns.map((c, i) => {
      return <AgGridColumn
        aggFunc={c.aggFunc}
        cellRenderer={c.renderer ? c.renderer : null}
        cellRendererParams={c.renderParams ? generateRenderParams(c.renderParams) : null}
        cellClassRules={classRules[c.field] || {}}
        enableRowGroup
        enableValue
        field={c.field}
        filter
        floatingFilter
        flex={c.flex}
        headerName={c.name}
        key={c.colId}
        resizable
        sortable
        valueFormatter={c.formatter ? formatters[c.formatter] : null}
        valueGetter={c.getter ? getters[c.getter] : null}
        hide={c.hide}
        pinned={c.pinned}
        pivot={c.pivot}
        pivotIndex={c.pivotIndex}
        rowDrag={i === 0}
        rowGroup={c.rowGroup}
        rowGroupIndex={c.rowGroupIndex}
        sort={c.sort}
        sortIndex={c.sortIndex}
        width={c.width}
      // headerCheckboxSelection={i === 0}
      // checkboxSelection={i === 0}
      />
    })
  }

  const deleteInventory = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/data/inventory/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      res.json()
    }).then(res => {
      setGridData(gridData.filter(r => r.inventoryId != id))
    })
  }

  const renderParams = {
    setRecModalActive,
    setEditRecModalActive,
    setCurrentInventory,
    setNotesInv,
    deleteInventory,
  }

  const saveCurrentColumnState = () => {
    const colState = gridColumnApi ? gridColumnApi.getColumnState() : gridColumns
    setGridColumns(filterEmptyColumns(colState))
  }

  const generateRenderParams = (paramsArr) => {
    const params = {}
    paramsArr.forEach((p) => {
      params[p] = renderParams[p]
    })
    params['saveCurrentColumnState'] = saveCurrentColumnState
    return params
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const onFirstDataRendered = () => {
    if (gridApi) {
      gridApi.setFilterModel(initialGridFilters)
    }

    if (gridColumnApi) {
      gridColumnApi.autoSizeAllColumns();
    }
  }

  const filterEmptyColumns = (cols) => {
    return cols.filter(c => isNaN(+c.colId))
  }

  const updateColumnState = (e) => {
    if (e.type === 'dragStopped' && e.target.className !== 'ag-header-cell-resize') { return }
    if (gridColumnApi) {
      setGridColumns(filterEmptyColumns(gridColumnApi.getColumnState()))
    }
  }

  const formatSnapshotInventories = (inventories) => {
    fetch(`${process.env.REACT_APP_API_URL}/data/rec_summary/job/${job}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(summaries => {
        const formattedInv = inventories.map(inv => {
          const rec = inv.recommendations.find(r => r.inventoryId == inv.inventoryId)
          return ({
            ...inv,
            proposedBaseRate: rec?.proposedBaseRate,
            proposedStateTax: rec?.proposedStateTax,
            proposedFederalTax: rec?.proposedFederalTax,
            proposedSurchargeAndFees: rec?.proposedSurchargeAndFees,
            proposedMonthlyTotal: rec ? (rec?.proposedBaseRate + rec?.proposedStateTax + rec?.proposedFederalTax + rec?.proposedSurchargeAndFees) : null,
            get difference() {
              return rec ? (inv.baseRate + inv.surchargeAndFees + inv.stateTax + inv.federalTax) - this.proposedMonthlyTotal : null
            },
            retroactive: rec?.retroactive,
            recCategory: summaries.find(sum => rec?.recSummaryId == sum.recSummaryId)?.recommendationType.category,
            recName: summaries.find(sum => rec?.recSummaryId == sum.recSummaryId)?.recommendationType.name,
            get monthlyCost() {
              return (inv.baseRate + inv.surchargeAndFees + inv.stateTax + inv.federalTax)
            }
          })
        })
        setGridData(formattedInv)
      })
  }

  const formatHealthCheckInventories = (inventories) => {
    const formattedInv = inventories.map(inv => {
      const rec = inv.recommendations.find(r => r.inventoryId == inv.inventoryId)
      return ({
        ...inv,
        proposedBaseRate: rec?.proposedBaseRate,
        proposedStateTax: rec?.proposedStateTax,
        proposedFederalTax: rec?.proposedFederalTax,
        proposedSurchargeAndFees: rec?.proposedSurchargeAndFees,
        retroactive: rec?.retroactive,
        recCategory: rec?.recommendationType.category,
        recName: rec?.recommendationType.name,
        get monthlyCost() {
          return (inv.baseRate + inv.surchargeAndFees + inv.stateTax + inv.federalTax)
        }
      })
    })
    setGridData(formattedInv)
  }

  const saveGridState = (name = '', resource = 0, allJobs = 0) => {
    const columnState = filterEmptyColumns(gridColumnApi.getColumnState())
    const filterState = gridApi.getFilterModel()
    const reqBody = {
      active: 1,
      columns: JSON.stringify(columnState),
      filters: JSON.stringify(filterState),
      jobId: allJobs ? null : job,
      spycareJobId: -1,
      name,
      gridTypeId: gridTypes.current.find(type => type.name === selectedType).gridTypeId,
      gridSubtypeId: 2
    }
    fetch(`${process.env.REACT_APP_API_URL}/data/grid?resource=${resource}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(reqBody)
    })
      .then(res => res.json())
      .then(res => {
        setGrid(null)
        setGridConfigs([...gridConfigs, res])
        setGridConfig(res.gridId)
      })
  }

  const saveSpycareGridState = (name = '', resource = 0, allJobs = 0) => {
    const columnState = filterEmptyColumns(gridColumnApi.getColumnState())
    const filterState = gridApi.getFilterModel()
    const reqBody = {
      active: 1,
      columns: JSON.stringify(columnState),
      filters: JSON.stringify(filterState),
      spycareJobId: allJobs ? null : spycareJob,
      jobId: -1,
      name,
      gridTypeId: gridTypes.current.find(type => type.name === selectedType).gridTypeId,
      gridSubtypeId: 2
    }
    fetch(`${process.env.REACT_APP_API_URL}/data/grid?resource=${resource}&spycare=1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(reqBody)
    })
      .then(res => res.json())
      .then(res => {
        setGrid(null)
        setGridConfigs([...gridConfigs, res])
        setGridConfig(res.gridId)
      })
  }

  const editGridState = (resource = 0) => {
    const columnState = filterEmptyColumns(gridColumnApi.getColumnState())
    const filterState = gridApi.getFilterModel()
    const reqBody = {
      ...editGrid,
      columns: JSON.stringify(columnState),
      filters: JSON.stringify(filterState),
    }
    fetch(`${process.env.REACT_APP_API_URL}/data/grid`, {
      method: 'PUT',
      body: JSON.stringify(reqBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(grid => {
        setGridConfigs(gridConfigs.map(g => {
          if (g.gridId == grid.gridId) {
            return grid
          }
          return g
        }))
        setEditGrid(null)
      })
  }

  const loadGridState = (configId) => {
    const config = gridConfigs.find(c => c.gridId == configId)
    if (config) {
      const columns = JSON.parse(config.columns)
      const filters = JSON.parse(config.filters)
      const loadedColumns = columns.map(col => {
        const matchingCol = invColumns.find(c => c.field === col.colId)
        return { ...matchingCol, ...col }
      })
      setGridColumns(loadedColumns)
      gridApi.setFilterModel(filters)
    }
  }

  useEffect(() => {
    const activeId = portal === 'SpyGlass' ? job : healthCheckId
    if (activeId) {
      fetch(`${process.env.REACT_APP_API_URL}/data/inventory/${portal === 'SpyGlass' ? 'job' : 'health_check'}/${activeId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(inventories => {
          if (portal === 'SpyGlass') {
            formatSnapshotInventories(inventories)
          } else {
            formatHealthCheckInventories(inventories)
          }
        })
        .catch(({ message }) => { })
    } else if (portal === 'SpyCare') {
      fetch(`${process.env.REACT_APP_API_URL}/data/health_check/spycare_job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(healthChecks => {
          healthChecks.sort((a, b) => b.healthCheckId - a.healthCheckId)
          const hcId = (healthChecks.length && healthChecks[0].healthCheckId) || null
          if (hcId) {
            fetch(`${process.env.REACT_APP_API_URL}/data/inventory/health_check/${hcId}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
              .then(res => res.json())
              .then(inventories => {
                formatHealthCheckInventories(inventories)
              })
              .catch(({ message }) => { })
          }
        })
    }
  }, [job, healthCheckId, portal, spycareJob, token])

  useEffect(() => {
    const activeId = portal === 'SpyGlass' ? job : spycareJob
    if (job || spycareJob) {
      fetch(`${process.env.REACT_APP_API_URL}/data/grid/${portal === 'SpyGlass' ? 'job' : 'spycare_job'}/${activeId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(grids => {
          if (grids.length) {
            setGridConfigs(grids)
            if (gridId) {
              const defaultConfig = grids.find(g => g.gridId == gridId)
              const columns = JSON.parse(defaultConfig.columns)
              const filters = JSON.parse(defaultConfig.filters)
              const loadedColumns = columns.map(col => {
                const matchingCol = invColumns.find(c => c.field === col.colId)
                return { ...matchingCol, ...col }
              })
              setSelectedType(defaultConfig.gridType.name)
              setGridColumns(loadedColumns)
              setInitialGridFilters(filters)
              setGridConfig(gridId)
            } else {
              setGridColumns(resetColumns(invColumns))
              setInitialGridFilters({})
            }
          } else {
            setGridColumns(resetColumns(invColumns))
            setInitialGridFilters({})
          }
        })
    } else {
      setGridColumns(resetColumns(invColumns))
      setInitialGridFilters({})
    }
  }, [job, gridId, portal, spycareJob, token])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/grid_type/grid_subtype/2`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(types => {
        gridTypes.current = types
      })
  }, [token])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/user/me`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(user => setAuthorities(user.authorities))
  }, [token])

  const closeModal = (recCreated = false) => {
    setRecModalActive(false)
    setEditRecModalActive(false)
    if (recCreated) {
      const activeId = portal === 'SpyGlass' ? job : healthCheckId
      if (activeId) {
        fetch(`${process.env.REACT_APP_API_URL}/data/inventory/${portal === 'SpyGlass' ? 'job' : 'health_check'}/${activeId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(inventories => {
            if (portal === 'SpyGlass') {
              formatSnapshotInventories(inventories)
            } else {
              formatHealthCheckInventories(inventories)
            }
          })
          .catch(({ message }) => { })
      } else if (portal === 'SpyCare') {
        fetch(`${process.env.REACT_APP_API_URL}/data/health_check/spycare_job/${spycareJob}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(healthChecks => {
            healthChecks.sort((a, b) => b.healthCheckId - a.healthCheckId)
            const hcId = (healthChecks.length && healthChecks[0].healthCheckId) || null
            if (hcId) {
              fetch(`${process.env.REACT_APP_API_URL}/data/inventory/health_check/${hcId}`, {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              })
                .then(res => res.json())
                .then(inventories => {
                  formatHealthCheckInventories(inventories)
                })
                .catch(({ message }) => { })
            }
          })
      }
    }
  }

  const statusBar = {
    statusPanels: [
      {
        statusPanel: 'agAggregationComponent',
        statusPanelParams: {
          aggFuncs: ['avg', 'count', 'min', 'max', 'sum']
        }
      }
    ]
  }

  return (
    <>
      <DashboardNav />
      {
        invAccess || portal === 'SpyCare'
          ?
          <>
            <div className="position-relative">
              <Container className="mt-3">
                <Row>
                  <Col xs={12} className="d-xl-flex justify-content-between align-content-center">
                    <h1 className="mb-0">Inventories</h1>
                    <div className={`${styles.tableControlsContainer}`}>
                      <div className={`${styles.tableControls}`}>
                        <div>
                          <Tab.Container id="list-group-tabs"
                            activeKey={selectedType}
                          >
                            <ListGroup horizontal>
                              <ListGroup.Item
                                variant='blueGreyExtraDark'
                                eventKey='Local'
                                action
                                onClick={() => {
                                  if (selectedType !== 'Local') {
                                    setGridConfig(null);
                                    setGridColumns(resetColumns(invColumns, 'Local'))
                                    gridApi.setFilterModel({})
                                  }
                                  setSelectedType('Local')
                                }}>
                                Local
                              </ListGroup.Item>
                              <ListGroup.Item
                                variant='blueGreyExtraDark'
                                eventKey='Data'
                                action
                                onClick={() => {
                                  if (selectedType !== 'Data') {
                                    setGridConfig(null);
                                    setGridColumns(resetColumns(invColumns, 'Data'))
                                    gridApi.setFilterModel({})
                                  }
                                  setSelectedType('Data')
                                }}>
                                Data
                              </ListGroup.Item>
                              <ListGroup.Item
                                variant='blueGreyExtraDark'
                                eventKey='LD'
                                action
                                onClick={() => {
                                  if (selectedType !== 'LD') {
                                    setGridConfig(null);
                                    setGridColumns(resetColumns(invColumns, 'LD'))
                                    gridApi.setFilterModel({})
                                  }
                                  setSelectedType('LD')
                                }}>
                                LD
                              </ListGroup.Item>
                              <ListGroup.Item
                                variant='blueGreyExtraDark'
                                eventKey='Wireless'
                                action
                                onClick={() => {
                                  if (selectedType !== 'Wireless') {
                                    setGridConfig(null);
                                    setGridColumns(resetColumns(invColumns, 'Wireless'))
                                    gridApi.setFilterModel({})
                                  }
                                  setSelectedType('Wireless')
                                }}>
                                Wireless
                              </ListGroup.Item>
                              <ListGroup.Item
                                variant='blueGreyExtraDark'
                                eventKey='All'
                                action
                                onClick={() => {
                                  if (selectedType !== 'All') {
                                    setGridConfig(null);
                                    setGridColumns(resetColumns(invColumns, 'All'))
                                    gridApi.setFilterModel({})
                                  }
                                  setSelectedType('All')
                                }}>
                                All
                              </ListGroup.Item>
                            </ListGroup>
                          </Tab.Container>
                        </div>
                        <div className={styles.gridControls}>
                          <Button
                            variant="outline-blueGreyDark"
                            onClick={() => {
                              setGridColumns(resetColumns(invColumns))
                              gridApi.setFilterModel({})
                            }}
                          >
                            Reset
                          </Button>
                          <div className={styles.gridDropdown}>
                            <Form.Select
                              value={gridConfig || ''}
                              onChange={(e) => {
                                saveCurrentColumnState()
                                setGridConfig(e.target.value)
                              }}
                            >
                              <option value="">Select View</option>
                              {
                                gridConfigs.filter(c => gridTypes.current.length && c.gridTypeId === gridTypes.current.find(type => type.name === selectedType).gridTypeId).map(c => (
                                  <option key={c.gridId} value={c.gridId}>
                                    {c.name}
                                  </option>
                                ))
                              }
                            </Form.Select>
                            <Button
                              className={styles.gridBtn}
                              variant="outline-blueGreyDark"
                              onClick={() => loadGridState(gridConfig)}
                              disabled={!gridConfig}
                            >
                              Load
                            </Button>
                            <Button
                              className={styles.gridBtn}
                              variant="outline-blueGreyDark"
                              onClick={() => setEditGrid(gridConfigs.find(g => g.gridId == gridConfig))}
                              disabled={!gridConfig}
                            >
                              Edit
                            </Button>
                            <Button
                              className={styles.gridBtn}
                              variant="cancel"
                              onClick={() => deleteGrid(gridConfig, () => {
                                fetch(`${process.env.REACT_APP_API_URL}/data/${portal === 'SpyCare' ? 'spycare/' : ''}document/job/${portal === 'SpyGlass' ? job : spycareJob}`, {
                                  headers: {
                                    Authorization: `Bearer ${token}`
                                  }
                                })
                                  .then(res => res.json())
                                  .then(docs => {
                                    const resource = docs.find(d => d.fileName === `/inventory${portal === 'SpyCare' ? '/health-check' : ''}?gridId=${gridConfig}`)
                                    const resourceId = portal === 'SpyGlass' ? resource.documentId : resource.spycareDocumentId
                                    if (resourceId) {
                                      fetch(`${process.env.REACT_APP_API_URL}/data${portal === 'SpyCare' ? '/spycare' : ''}/document/${resourceId}`, {
                                        method: 'DELETE',
                                        headers: {
                                          Authorization: `Bearer ${token}`
                                        }
                                      })
                                    }
                                  })
                                setGridConfig(null)
                                setGridConfigs(gridConfigs.filter(g => g.gridId != gridConfig))
                              }, token)}
                              disabled={!gridConfig}
                            >
                              Delete
                            </Button>
                          </div>
                          <Button
                            variant="outline-blueGreyDark"
                            onClick={() => {
                              saveCurrentColumnState()
                              setGrid({ name: '', saveAsResource: false })
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>

            </div>
            <Container className="mt-3 mb-0 mw-100 px-0">
              <Modal show={notesInv} onHide={() => setNotesInv(null)}
                dialogClassName="modal-w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <h2>Notes</h2>
                </Modal.Header>
                <div>
                  <Modal.Body>
                    <div>
                      <div className={styles.notesSection}>
                        <label>Notes</label>
                        <input type="text" value={notesInv?.notes || ''} onChange={(e) => setNotesInv({ ...notesInv, notes: e.target.value })} />
                      </div>
                    </div>
                  </Modal.Body>
                </div>
                <Modal.Footer>
                  <Button
                    className="d-block mx-auto"
                    variant="secondary"
                    size="md"
                    onClick={() => {
                      fetch(`${process.env.REACT_APP_API_URL}/data/inventory`, {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify([notesInv])
                      })
                        .then(res => res.json())
                        .then(res => {
                          gridApi.getRowNode(notesInv.inventoryId).updateData(notesInv)
                          setGridData(gridData.map(i => {
                            if (i.inventoryId === notesInv.inventoryId) {
                              return { ...i, notes: notesInv.notes };
                            }
                            
                            return i;
                          }))
                          setNotesInv(null)
                        })
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
              {
                authorities?.includes("ROLE_Employee") ?
                  portal === 'SpyGlass'
                    ?
                    <AddRecommendationModal isRecModalActive={isRecModalActive} callback={closeModal} jobId={job} inventoryId={currentInventory} inventories={gridData} />
                    : null
                  : null
              }
              {
                authorities?.includes("ROLE_Employee") ?
                  portal === 'SpyGlass'
                    ?
                    <EditRecommendationModal isRecModalActive={isEditRecModalActive} callback={closeModal} jobId={job} inventoryId={currentInventory} inventories={gridData} />
                    : null
                  : null
              }
              <GridModal grid={grid} setGrid={setGrid} saveGridState={portal === 'SpyGlass' ? saveGridState : saveSpycareGridState} authorities={authorities} />
              <EditGridModal editGrid={editGrid} setEditGrid={setEditGrid} editGridState={editGridState} jobId={portal === 'SpyGlass' ? job : spycareJob} spyCare={portal === 'SpyCare' ? 1 : 0} />
              <Row>
                <Col xs={12}>
                  <div className="card-h-100 mb-0 shadow-none">
                    <Card.Body className="py-0">
                      <div className="table-container" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="ag-theme-alpine p-0 pb-3" style={{ height: 'calc(100vh - 196px)', width: '100vw', padding: '3em' }}>
                          {
                            (gridData && gridColumns && initialGridFilters && invAccess)
                              ? (
                                <AgGridReact
                                  ref={gridRef}
                                  rowData={generateRowData()}
                                  frameworkComponents={renderers}
                                  rowGroupPanelShow="always"
                                  onGridReady={onGridReady}
                                  enableRangeSelection
                                  // rowSelection={'multiple'}
                                  sideBar
                                  statusBar={statusBar}
                                  rowDragManaged={true}
                                  animateRows={true}
                                  onFirstDataRendered={onFirstDataRendered}
                                  onColumnVisible={updateColumnState}
                                  onColumnPinned={updateColumnState}
                                  onColumnMoved={updateColumnState}
                                  onColumnPivotChanged={updateColumnState}
                                  onDragStopped={updateColumnState}
                                  onColumnRowGroupChanged={updateColumnState}
                                  onSortChanged={updateColumnState}
                                  getRowNodeId={row => row.inventoryId}
                                  rememberGroupStateWhenNewData={true}
                                  rowClassRules={rowClassRules}
                                >
                                  {generateColumns()}
                                </AgGridReact>
                              )
                              : null
                          }
                        </div>
                      </div>
                    </Card.Body>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
          : <h3 className={styles.noInventories}>You do not have access to view the Inventories</h3>
      }
    </>
  );
}

export default Inventories;
